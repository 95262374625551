.wrapper {
  position: relative;
  padding-top: rem(150px);
  padding-bottom: rem(130px);
  background-image: var(--mantine-api-url);
  background-size: cover;
  background-position: center;

  @media (max-width: $mantine-breakpoint-xs) {
    padding-top: rem(60px);
    padding-bottom: rem(60px);
  }
}

.inner {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-weight: 800;
  font-size: rem(40px);
  letter-spacing: rem(-1px);
  padding-left: var(--mantine-spacing-md);
  padding-right: var(--mantine-spacing-md);
  color: var(--mantine-color-white);
  margin-bottom: var(--mantine-spacing-xs);
  text-align: center;
  font-family: 'Greycliff CF', var(--mantine-font-family);

  @media (max-width: $mantine-breakpoint-xs) {
    font-size: rem(28px);
    text-align: center;
  }
}

.highlight {
  color: var(--mantine-color-red-8);
}

.description {
  color: var(--mantine-color-gray-0);
  text-align: justify;

  @media (max-width: $mantine-breakpoint-xs) {
    font-size: var(--mantine-font-size-md);
    text-align: justify;
  }
}
