.controls {
  margin-top: calc(var(--mantine-spacing-xl) * 1.5);
  display: grid;
  grid-template-columns: repeat(2, 15rem);
  grid-template-rows: repeat(2, 1fr);
  gap: var(--mantine-spacing-xs);

  /* align-content: center; */
  /* display: flex;
  justify-content: center; */
  /* padding-left: var(--mantine-spacing-md);
  padding-right: var(--mantine-spacing-md); */

  @media (max-width: $mantine-breakpoint-xs) {
    grid-template-columns: 15rem;
    grid-template-rows: repeat(3, 1fr);
    gap: var(--mantine-spacing-xs);
  }
}

.control {
  /* background-image: linear-gradient(
    to right,
    var(--mantine-color-orange-1) 0%,
    var(--mantine-color-red-9) 51%,
    var(--mantine-color-red-9) 100%
  ); */
  /* background-image: linear-gradient(to right, #314755 0%, #26a0da 51%, #314755 100%); */
  background-position: 100% 0;
  background-size: 200% 100%;
  transition: all 0.25s ease-in;

  height: rem(42px);

  font-size: var(--mantine-font-size-md);

  &:hover {
    background-image: linear-gradient(
      to left,
      transparent,
      transparent 50%,
      var(--mantine-primary-color-9) 30%,
      var(--mantine-primary-color-3)
    );
    background-position: 0 0;
  }

  /* &:hover {
    background: linear-gradient(
      45deg,
      var(--mantine-color-white) 0%,
      var(--mantine-color-red-filled) 100%
    );
  } */

  &:first-of-type {
    grid-row: 1;
    grid-column: 1 / span 2;

    @media (max-width: $mantine-breakpoint-xs) {
      grid-row: 1;
      grid-column: 1;
    }
  }

  &:nth-child(2) {
    grid-row: 2;
    grid-column: 1;

    @media (max-width: $mantine-breakpoint-xs) {
      grid-row: 2;
      grid-column: 1;
    }
  }

  &:nth-child(3) {
    grid-row: 2;
    grid-column: 2;

    @media (max-width: $mantine-breakpoint-xs) {
      grid-row: 3;
      grid-column: 1;
    }
  }

  @media (max-width: $mantine-breakpoint-xs) {
    &:not(:first-of-type) {
      margin-left: 0;
    }
  }
}

.secondaryControl {
  color: var(--mantine-color-white);
  background-color: rgba(255, 255, 255, 0.4);

  @mixin hover {
    background-color: rgba(255, 255, 255, 0.45);
  }
}
