.startPage_wrapper__nIq59 {
  position: relative;
  padding-top: calc(9.375rem * var(--mantine-scale));
  padding-bottom: calc(8.125rem * var(--mantine-scale));
  background-image: var(--mantine-api-url);
  background-size: cover;
  background-position: center;
}

  @media (max-width: 36em) {.startPage_wrapper__nIq59 {
    padding-top: calc(3.75rem * var(--mantine-scale));
    padding-bottom: calc(3.75rem * var(--mantine-scale))
}
  }

.startPage_inner__tJz4I {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.startPage_title__zY_Ip {
  font-weight: 800;
  font-size: calc(2.5rem * var(--mantine-scale));
  letter-spacing: calc(-0.0625rem * var(--mantine-scale));
  padding-left: var(--mantine-spacing-md);
  padding-right: var(--mantine-spacing-md);
  color: var(--mantine-color-white);
  margin-bottom: var(--mantine-spacing-xs);
  text-align: center;
  font-family: 'Greycliff CF', var(--mantine-font-family);
}

@media (max-width: 36em) {

.startPage_title__zY_Ip {
    font-size: calc(1.75rem * var(--mantine-scale));
    text-align: center
}
  }

.startPage_highlight__NjnwP {
  color: var(--mantine-color-red-8);
}

.startPage_description__a3P_d {
  color: var(--mantine-color-gray-0);
  text-align: justify;
}

@media (max-width: 36em) {

.startPage_description__a3P_d {
    font-size: var(--mantine-font-size-md);
    text-align: justify
}
  }

.WelcomeBlock_controls__UpjFW {
  margin-top: calc(var(--mantine-spacing-xl) * 1.5);
  display: grid;
  grid-template-columns: repeat(2, 15rem);
  grid-template-rows: repeat(2, 1fr);
  gap: var(--mantine-spacing-xs);
}

  /* align-content: center; */
  /* display: flex;
  justify-content: center; */
  /* padding-left: var(--mantine-spacing-md);
  padding-right: var(--mantine-spacing-md); */

  @media (max-width: 36em) {.WelcomeBlock_controls__UpjFW {
    grid-template-columns: 15rem;
    grid-template-rows: repeat(3, 1fr);
    gap: var(--mantine-spacing-xs)
}
  }

.WelcomeBlock_control__zwedJ {
  /* background-image: linear-gradient(
    to right,
    var(--mantine-color-orange-1) 0%,
    var(--mantine-color-red-9) 51%,
    var(--mantine-color-red-9) 100%
  ); */
  /* background-image: linear-gradient(to right, #314755 0%, #26a0da 51%, #314755 100%); */
  background-position: 100% 0;
  background-size: 200% 100%;
  transition: all 0.25s ease-in;

  height: calc(2.625rem * var(--mantine-scale));

  font-size: var(--mantine-font-size-md);
}

.WelcomeBlock_control__zwedJ:hover {
    background-image: linear-gradient(
      to left,
      transparent,
      transparent 50%,
      var(--mantine-primary-color-9) 30%,
      var(--mantine-primary-color-3)
    );
    background-position: 0 0;
  }

/* &:hover {
    background: linear-gradient(
      45deg,
      var(--mantine-color-white) 0%,
      var(--mantine-color-red-filled) 100%
    );
  } */

.WelcomeBlock_control__zwedJ:first-of-type {
    grid-row: 1;
    grid-column: 1 / span 2;
  }

@media (max-width: 36em) {

.WelcomeBlock_control__zwedJ:first-of-type {
      grid-row: 1;
      grid-column: 1
  }
    }

.WelcomeBlock_control__zwedJ:nth-child(2) {
    grid-row: 2;
    grid-column: 1;
  }

@media (max-width: 36em) {

.WelcomeBlock_control__zwedJ:nth-child(2) {
      grid-row: 2;
      grid-column: 1
  }
    }

.WelcomeBlock_control__zwedJ:nth-child(3) {
    grid-row: 2;
    grid-column: 2;
  }

@media (max-width: 36em) {

.WelcomeBlock_control__zwedJ:nth-child(3) {
      grid-row: 3;
      grid-column: 1
  }
    }

@media (max-width: 36em) {
    .WelcomeBlock_control__zwedJ:not(:first-of-type) {
      margin-left: 0;
    }
  }

.WelcomeBlock_secondaryControl__zbez6 {
  color: var(--mantine-color-white);
  background-color: rgba(255, 255, 255, 0.4);
}

@media (hover: hover) {

  .WelcomeBlock_secondaryControl__zbez6:hover {
    background-color: rgba(255, 255, 255, 0.45);
  }
}

@media (hover: none) {

  .WelcomeBlock_secondaryControl__zbez6:active {
    background-color: rgba(255, 255, 255, 0.45);
  }
}

